/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React from 'react';

const Fct = ({width,height,color,info,onClick}) => {

    const handleClick = (e) => {
        onClick();
    }

    return ( 
        <svg>
            <g style={{ cursor : "pointer"}} onPointerDown={handleClick}>
                <path stroke={color} fill={color} d={`M${width-65} 0  L${width-15} 0 Q ${width} 0 ${width} 15 L${width} 40 Q ${width-55} 50 ${width-65} 0`} />
                <text fill='white' x={width-48} y={25} style={{fontFamily:'cmunso', fontSize: "16px", fontWeight : "bold"}}>{info}</text>
            </g>
        </svg>
     );
}

export default Fct;