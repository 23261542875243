/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/

import React, { useState, useEffect, Fragment,useRef } from 'react';
import Infos from './Infos';

const ex_label = 24;
const ey_label = 10;
	
const ex_graduation = 8;
const ey_graduation = 15;
		
const arrowLength = 10;
const arrowWidth = 9;

const color = "#000000";		
const delta = 20;
	

const Graphique = ({xmin,xmax,ymin,ymax,x0,y0,width,height,xLabel,yLabel,dxmax,dxmin,dymax,dymin,lx,ly,colcourbe,line,mode,show,i,config,uniteF}) => {

    const legraphe = useRef();
    
    const [echelleX, setechelleX] = useState(width/(xmax-xmin))

    const [echelleY, setechelleY] = useState(height/(ymax-ymin))

    const [xOrig, setxOrig] = useState(width-xmax*echelleX)

    const [yOrig, setyOrig] = useState(height+ymin*echelleY)

    const [xMin, setxMin] = useState(xmin*echelleX)
    const [xMax, setxMax] = useState(xmax*echelleX)

    const [yMin, setyMin] = useState(yOrig - ymin*echelleY)
    const [yMax, setyMax] = useState(yOrig - ymax*echelleY)

    const [xLab, setxLab] = useState(xLabel || 'x' )

    const [yLab, setyLab] = useState(yLabel || 'y')

    const [axes, setaxes] = useState("")

    const [grilleX, setgrilleX] = useState("")
    const [grilleX2, setgrilleX2] = useState("")

    const [grilleY, setgrilleY] = useState("")
    const [grilleY2, setgrilleY2] = useState("")

    const [courbe, setcourbe] = useState("")
    const [courbe2, setcourbe2] = useState("")

    const [posX, setposX] = useState(0)

    const [posY, setposY] = useState(0)

    const [showInfo, setshowInfo] = useState(false)

    useEffect(() => {
        setechelleX(width/(xmax-xmin))
    }, [xmin,xmax,width])


    useEffect(() => {
        setechelleY(height/(ymax-ymin))
    }, [ymin,ymax,height])

    useEffect(() => {
        setxOrig(width-xmax*echelleX);
    }, [echelleX,width,xmax])

    useEffect(() => {
        setyOrig(height+ymin*echelleY);
    }, [echelleY,height,ymin])   

    useEffect(() => {
        drawAxes();
        drawGrilles();
        tracerCourbe();
    }, [xMax,yMax,xMin,yMin,lx,ly])


    useEffect(() => {
        setxMin(xmin*echelleX);
    }, [xmin,echelleX])

    useEffect(() => {
        setxMax(xmax*echelleX);
    }, [xmax,echelleX])

    useEffect(() => {
        setyMin(yOrig - ymin*echelleY);
    }, [yOrig,ymin,echelleY])

    useEffect(() => {
        setyMax(yOrig - ymax*echelleY);
    }, [yOrig,ymax,echelleY])

    useEffect(() => {
        setxLab(xLabel);
    }, [xLabel])

    
    const drawAxes = () => {
        let axes = `M ${x0} ${y0 - yOrig+yMin} L ${x0} ${y0 - yOrig+yMax-delta} L ${x0-arrowWidth/2} ${y0 - yOrig+yMax+arrowLength-delta} Q ${x0} ${y0 - yOrig+yMax+arrowLength/2-delta} ${x0+arrowWidth/2} ${y0 - yOrig+yMax+arrowLength-delta} L ${x0} ${y0 - yOrig+yMax-delta} M ${x0 + xOrig + xMin} ${y0 - yOrig+yOrig} L ${x0 + xOrig+xMax+delta} ${y0 - yOrig+yOrig} L ${x0 + xOrig+xMax-arrowLength+delta} ${y0 - yOrig+yOrig-arrowWidth/2} Q ${x0 + xOrig+xMax-arrowLength/2+delta} ${y0 - yOrig+yOrig} ${x0 + xOrig+xMax-arrowLength+delta} ${yOrig+arrowWidth/2+y0 - yOrig} L ${x0 + xOrig+xMax+delta} ${yOrig+y0 - yOrig}`;
        setaxes(axes);
    }

    const range = (start, stop, step) => {
        let array = [];
        
        for(var i = start; i <= stop; i+=step ){
            array.push(i);
        }
        return array;
    }

    const drawGrilles = () => {
        let gX = "";
        let gX2 = "";

        let gY = "";
        let gY2 = "";

        // 1- Lignes verticales

        // ligne secondaire positives

        range(xmin,xmax,dxmin).forEach( elem => {
			let posX = x0 + xOrig + elem*echelleX;
			let posY1 = y0 - yOrig;
			let posY2 = y0 - yOrig+height;
			gX += `M ${posX} ${posY1} L ${posX} ${posY2}`;
		});

        // ligne primaire positives

        range(xmin,xmax,dxmax).forEach( (elem,index) => {
						
			let posX = x0 + xOrig + elem*echelleX;			
			let posY1 = y0 - yOrig;
			let posY2 = y0 - yOrig+height+5;			
			gX2 += `M ${posX} ${posY1} L ${posX} ${posY2}`;
			
		});

        // 2 - Lignes horizontales
		
		//lignes secondaires positives

        for(var i = 0; i <= ymax; i+= dymin){
            let posX1 = x0 ;
			let posX2 = x0 + width;
			let posY = y0 - yOrig + yOrig - i*echelleY;
			gY += `M ${posX1} ${posY} L ${posX2} ${posY}`;
        }

        //lignes secondaires négatives

        for(var j = 0; j <= -1*ymin; j+= dymin){
            let posX1 = x0 ;
			let posX2 = x0 + width;
			let posY = y0 - yOrig + yOrig + j*echelleY;
			gY += `M ${posX1} ${posY} L ${posX2} ${posY}`;
        }

        // lignes principales positives
			
		range(0,ymax,dymax).forEach( (elem,index) => {
			let posX1 = x0-5;
			let posX2 = x0 + width;
			let posY = y0 - yOrig + yOrig - elem*echelleY;
			gY2 += `M ${posX1} ${posY} L ${posX2} ${posY}`;
		});
		
        // lignes principales négative
		
		range(0, -1*ymin,dymax).forEach( (elem,index) => {
			let posX1 = x0-5;
			let posX2 = x0 + width;
			let posY = y0 - yOrig + yOrig + elem*echelleY;
			gY2 += `M ${posX1} ${posY} L ${posX2} ${posY}`;
		});

        setgrilleX(gX);
        setgrilleX2(gX2);

        setgrilleY(gY);
        setgrilleY2(gY2);
    }

    const tracerCourbe = () => {

        let c = "";
        let c2 = "";

        let nb = 0;

        lx.forEach( (elem,index) => {

            //console.log(elem);
                
            const point = getPos(index);

            if(mode === 1){

                let p1;
                let p2;
                let p3;

                if(index === 0){
                    p1 = getPos(index-1);
                }else{
                    p1 = getPos(index);
                }
                
                if(index === 0 || index === 1){
                    p2 = getPos(index-2);
                }else{
                    p2 = getPos(index);
                }

                if(index === lx.length){
                    p3 = getPos(index+1);
                }else{
                    p3 = getPos(index);
                }

                const [cpsX, cpsY] = pointControl(p1, p2, point);
                //console.log(`cpsX : ${cpsX}`);
                //console.log(`cpsY : ${cpsY}`);
                    
                const [cpeX, cpeY] = pointControl(point, p1, p3, true);
                //console.log(`cpeX : ${cpeX}`);
                //console.log(`cpeY : ${cpeY}`);
                
                if(index === 0 || index === 1){
                    c += `M ${point[0]} ${point[1]}`;
                }else{	
                    c += `C ${cpsX} ${cpsY} ${cpeX} ${cpeY} ${point[0]} ${point[1]} `;	
                    //c += `L ${point[0]} ${point[1]}`;
                }

            }else{

                if(ly[index] !== 0){

                    if(i === 1){
                        c += `M ${point[0]} ${point[1]} L ${point[0]} ${y0 - yOrig+yMin}`;
                    }else if(i === 3){
                        if(nb === 0 || nb === 2){
                            c2 += `M ${point[0]} ${point[1]} L ${point[0]} ${y0 - yOrig+yMin}`;
                        }else{
                            c += `M ${point[0]} ${point[1]} L ${point[0]} ${y0 - yOrig+yMin}`;
                        }
                        nb++;
                    }
                }
            }
                
        });


        setcourbe(c);
        setcourbe2(c2);

    }

    const getPos = (index) => {
        return [x0 + xOrig + lx[index]*echelleX,y0 - yOrig + yOrig - ly[index]*echelleY];
    }

    const ligne = (pointA, pointB) => {
        const lengthX = pointB[0] - pointA[0]
        const lengthY = pointB[1] - pointA[1]
        return {
          length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
          angle: Math.atan2(lengthY, lengthX)
        }
    }

    const pointControl = (current, previous, next, reverse) => {

        const p = previous || current
        const n = next || current
      
        const smoothing = 0.2
        
        const o = ligne(p, n)
        
        const angle = o.angle + (reverse ? Math.PI : 0)
        const length = o.length * smoothing
        
        const x = current[0] + Math.cos(angle) * length
        const y = current[1] + Math.sin(angle) * length
        return [x, y]
    }

    const drag = (event) => {
        let point = legraphe.current.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(legraphe.current.getScreenCTM().inverse());


        const x = (point.x-x0-xOrig)/echelleX;

        const y = (y0-point.y)/echelleY;

        setposX(x);
        setposY(y);

        setshowInfo(true);

    }

    const stopdrag = () => {
        setshowInfo(false);
    }

    return ( 
        <svg x="0" y={show === true ? "0" : "-1000"} ref={legraphe} className='container' width="100%" height="100%">
            <path className='grilleYsecondaire'  d={grilleY} stroke={"#b1b1b1"} strokeWidth="1" strokeLinecap="round" />
            <path className='grilleXsecondaire'  d={grilleX} stroke={"#b1b1b1"} strokeWidth="1" strokeLinecap="round" />
            <path className='grilleY'  d={grilleY2} stroke={"#808080"} strokeWidth="1.5" strokeLinecap="round" />
            <path className='grilleX' d={grilleX2} stroke={"#808080"} strokeWidth="1.5" strokeLinecap="round" />
            <path className='axes'  d={axes} stroke={color} strokeWidth="2" strokeLinecap="round"/>
            <path className='courbe' style={{ cursor : "crosshair"}} d={courbe} stroke={colcourbe} strokeWidth={line} strokeLinecap="round" fill="none" onPointerMove={drag} onPointerLeave={stopdrag}/>
            <path className='courbe2' style={{ cursor : "crosshair"}} d={courbe2} stroke={colcourbe} strokeWidth={line} strokeLinecap="round" fill="none" onPointerMove={drag} onPointerLeave={stopdrag}/>
            <text x={x0 + xOrig + xMax + delta + ex_label - 37} y={y0 - yOrig+yOrig - ey_label/2 - 7 } style={{fontFamily:'LatoBold', fontSize: `${ mode === 1 ? "15px" : "12px"}`, fontWeight: "bold", textAlign : "left"}}>{xLab}</text>
            <text x={x0 + ex_label - 15} y={y0 - yOrig+yOrig - ymax*echelleY - ey_label - 5} style={{fontFamily:'LatoBold', fontSize: "15px", fontWeight: "bold", textAlign : "left"}}>{yLab}</text>
            {
                
                range(dxmax,xmax,dxmax).map( (elem,index) => {
                    if(index === 0){
                        return (
                            <Fragment key={`xpos${index}`}>
                            {
                                xmin === 0 && (
                                    <text key={"xplab77"+0} x={x0} y={y0 - yOrig + height + 5 + ey_graduation} fill={"#4f4f4f"} textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "15px", fontWeight: "bold", textAlign : "center"}}>{xmin}</text> 
                                )
                            }
                                <text key={"xplab7701"} x={x0 + xOrig + xOrig + elem*echelleX} y={y0 - yOrig + height + 5 + ey_graduation} fill={"#4f4f4f"}  textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "15px", fontWeight: "bold", textAlign : "center"}}>{dxmax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                            </Fragment>
                        )
                    }

                    return (
                        <text key={"xplab22"+index} x={x0+ xOrig + elem*echelleX} y={y0 - yOrig + height + 5 + ey_graduation} fill={"#4f4f4f"} textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "15px", fontWeight: "bold", textAlign : "center"}}>{dxmax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                    )
                })	     	
            }
            {
                range(dymax,ymax,dymax).map( (elem,index) => {
                    if(index === 0){
                        return (
                            <Fragment key={`ypos${index}`}>
                                <text key={"yplab88"+0} x={x0 -1*ex_graduation} y={y0 - yOrig + yOrig + 0.5} dominantBaseline="middle" fill={"#4f4f4f"} textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>0</text>
                                <text key={"yplab8801"} x={x0 -1*ex_graduation} y={y0 - yOrig + yOrig - elem*echelleY + 0.5} dominantBaseline="middle" fill={"#4f4f4f"}  textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>{dymax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                            </Fragment>
                        )
                    }

                    return (
                        <text key={"yplab00"+index} x={x0 -1*ex_graduation} y={y0 - yOrig  + yOrig - elem*echelleY + 0.5} fill={"#4f4f4f"} dominantBaseline="middle" textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>{dymax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                    )
                })
            }

            {
                range(dymax,-1*ymin,dymax).map( (elem,index) => {
                    if(index === 0 && ymax === 0){
                        return (
                            <Fragment key={`yneg${index}`}>
                                <text key={"yplab99"+0} x={x0 + xOrig -1*ex_graduation} y={y0 - yOrig + yOrig + 0.5} dominantBaseline="middle" fill={"#4f4f4f"} textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>0</text>
                                <text key={"yplab9901"} x={x0 + xOrig -1*ex_graduation} y={y0 - yOrig + yOrig + elem*echelleY + 0.5} dominantBaseline="middle" fill={"#4f4f4f"}  textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>-{dymax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                            </Fragment>
                        )
                    }

                    return (
                        <text key={"yplab11"+index} x={x0 + xOrig -1*ex_graduation} y={y0 - yOrig  + yOrig + elem*echelleY + 0.5} fill={"#4f4f4f"} dominantBaseline="middle" textAnchor="end" style={{fontFamily:'LatoBold', fontSize: "14px", fontWeight: "bold", textAlign : "right"}}>-{dymax < 1 ? String(parseFloat(elem).toFixed(1)).replace(".",",") : parseInt(elem)}</text>
                    )
                })
            }
            <Infos mode={mode} width={width} height={height} ymax={ymax} ymin={ymin} posX={posX} posY={posY} grandeur={config.grandeurY} grandeur2={config.grandeurX} unite={config.uniteY} unite2={config.uniteX} showInfo={showInfo} uniteF={uniteF}/>
        </svg>
     );
}
 
export default Graphique;