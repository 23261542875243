import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('frequenceS')) || 250, // hertz
}

export const fsSlice = createSlice({
  name: 'fs',
  initialState,
  reducers: {
    plusFs: (state) => {
        if(state.value < 600){
            state.value = parseFloat(state.value+10);
        }
    },
    moinsFs: (state) => {
        if(state.value > 200){
            state.value = parseFloat(state.value-10);
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusFs, moinsFs } = fsSlice.actions

export default fsSlice.reducer