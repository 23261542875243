/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, { useState, useEffect } from 'react'

const Infos2 = ({posX,posY,data,showInfo}) => {
    
    const [largeur, setlargeur] = useState(300)
    const [hauteur, sethauteur] = useState(28)

    const [largeur2, setlargeur2] = useState(300)
    const [hauteur2, sethauteur2] = useState(28)

    const [largeur3, setlargeur3] = useState(230)
    const [hauteur3, sethauteur3] = useState(28)

    const [largeur4, setlargeur4] = useState(230)
    const [hauteur4, sethauteur4] = useState(28)

    const [largeur5, setlargeur5] = useState(160)
    const [hauteur5, sethauteur5] = useState(28)

    if(posX > 21 && posX < 88 && posY >= 32 && posY <= 49){
            return (
                <svg x={ posX+2 } y={ posY-2-hauteur } width={largeur} height={hauteur}>
                    <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                    <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Amplitude du signal à transmettre</text>
                </svg>
            )
    }else if(posX > 26 && posX < 48 && posY >= 77 && posY <= 102){
        return (
            <svg x={ posX+2 } y={ posY-2-hauteur2 } width={largeur2} height={hauteur2}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Fréquence du signal à transmettre</text>
            </svg>
        )
    }else if(posX > 20 && posX < 89 && posY >= 121 && posY <= 145){
        return (
            <svg x={ posX+2 } y={ posY-2-hauteur3 } width={largeur3} height={hauteur3}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Amplitude de la porteuse</text>
            </svg>
        )
    }else if(posX > 322 && posX < 348 && posY >= 31 && posY <= 54){
        return (
            <svg x={ posX-largeur4/2 } y={ posY-4-hauteur4 } width={largeur4} height={hauteur4}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Fréquence de la porteuse</text>
            </svg>
        )
    }else if(posX > 321 && posX < 351 && posY >= 76 && posY <= 101){
        return (
            <svg x={ posX-largeur5/2 } y={ posY-4-hauteur5 } width={largeur5} height={hauteur5}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Tension continue</text>
            </svg>
        )
    }else{
        return null;
    }
    
}
 
export default Infos2;