/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect,Fragment} from 'react';
import Graphique from './Graphique';
import Fct from './Fct';

const TensionS = ({x,y,width,height,tension,frequence,temps,color,fmax}) => {

    const [xList, setxList] = useState([])

    const [xList2, setxList2] = useState([])

    const [yList, setyList] = useState([])

    const [yList2, setyList2] = useState([])

    const [deltaX, setdeltaX] = useState(0.01)

    const [mode, setmode] = useState(true)

    const [config, setconfig] = useState({
        xMin : 0,
        xMax : temps,
        yMin : -1*tension,
        yMax : tension,
        grandeurY : "U",
        uniteY : "V",
        grandeurX : "t",
        uniteX : "ms",
    })

    useEffect(() => {

        setconfig({
            xMin : 0,
            xMax : temps,
            yMin : -1*tension,
            yMax : tension,
            grandeurY : "U",
            uniteY : "V",
            grandeurX : "t",
            uniteX : "ms",
        })

        let x = 0;
        let liX = [];
        let liY = [];
      
        while(x <= config.xMax){
            liX.push(parseFloat(x).toFixed(2));
            liY.push(parseFloat(tension*Math.cos(2*3.14*frequence*x*0.001)));
            x += deltaX;
        }

        setxList(liX);
        setyList(liY);

        let x2 = 0;
        let liX2 = [];
        let liY2 = [];

        while(x2 <= fmax){
            if(parseFloat(x2).toFixed(2) === parseFloat(frequence).toFixed(2)){
                liY2.push(parseFloat(tension));
            }else{
                liY2.push(parseFloat(0));
            }
            liX2.push(parseFloat(x2).toFixed(2));
            x2 += deltaX;
        }

        setxList2(liX2);
        setyList2(liY2);

    }, [tension,frequence,temps])
    

    const handleClick = () => {
        setmode(!mode);
    }

    return ( 
        <svg  className='tension' x={x} y={y} width={width} height={height} >
            <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#FFFFFF' />          
            <Graphique config={config} i={1} show={mode === true} mode={1} line="4" lx={xList} ly={yList} colcourbe={color} dxmax={1} dxmin={0.5} dymax={1} dymin={0.5} xmin={config.xMin} xmax={config.xMax} ymin={config.yMin} ymax={config.yMax} x0={35} y0={height/2+5} width={width-80} height={height-70} xLabel={`${config.grandeurX}(${config.uniteX})`} yLabel={`${config.grandeurY}(${config.uniteY})`}/>
            <Graphique config={config} i={1} show={mode === false} mode={2} line="4" lx={xList2} ly={yList2} colcourbe={color} dxmax={100} dxmin={50} dymax={1} dymin={0.2} xmin={0} xmax={fmax} ymin={0} ymax={config.yMax} x0={35} y0={height-30} width={width-80} height={height-70} xLabel={`f(Hz)`} uniteF={'Hz'} yLabel={`${config.grandeurY}(${config.uniteY})`}/>
            <Fct info={mode === true ? "U(f)" : "U(t)"} width={width} height={height} color={color} onClick={handleClick}/>
            <text x={width*0.5-110} y="25" fill={color} style={{fontFamily:'LatoBold', fontSize: "22px", fontWeight: "bold"}}>Signal à transmettre</text>
        </svg>
     );
}
 
export default TensionS;