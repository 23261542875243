import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('tensionMaxC')) || 2,
}

export const ucSlice = createSlice({
  name: 'uc',
  initialState,
  reducers: {
    plusUc: (state) => {
        if(state.value < 6){
            state.value = parseFloat(state.value+0.1);
            localStorage.setItem("tensionMaxC", parseFloat(state.value).toFixed(1));
        }
    },
    moinsUc: (state) => {
        if(state.value > 1){
            state.value = parseFloat(state.value-0.1);
            localStorage.setItem("tensionMaxC", parseFloat(state.value).toFixed(1));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusUc, moinsUc } = ucSlice.actions

export default ucSlice.reducer