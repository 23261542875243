import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('tensionMaxP')) || 3,
}

export const upSlice = createSlice({
  name: 'us',
  initialState,
  reducers: {
    plusUp: (state) => {
        if(state.value < 6){
            state.value = parseFloat(state.value+0.1);
            localStorage.setItem("tensionMaxP", parseFloat(state.value).toFixed(1));
        }
    },
    moinsUp: (state) => {
        if(state.value > 3){
            state.value = parseFloat(state.value-0.1);
            localStorage.setItem("tensionMaxP", parseFloat(state.value).toFixed(1));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusUp, moinsUp } = upSlice.actions

export default upSlice.reducer