import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('frequenceS')) || 4, // hertz
}

export const fpSlice = createSlice({
  name: 'fp',
  initialState,
  reducers: {
    plusFp: (state) => {
        if(state.value < 8){
            state.value = parseFloat(state.value+0.1);
        }
    },
    moinsFp: (state) => {
        if(state.value > 2){
            state.value = parseFloat(state.value-0.1);
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusFp, moinsFp } = fpSlice.actions

export default fpSlice.reducer