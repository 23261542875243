/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect,useRef} from 'react';

const Plus = ({x,y,changeValue}) => {

    const [color, setcolor] = useState("#ff0000");

    const interval = useRef(null);

    const handleDeClick = (e) => {
        setcolor("#ff0000");
        clearInterval(interval.current);
        interval.current = null;
    }

    const handleClick = (e) => {
        setcolor("#ff3333");
            if (interval.current === null) {
            interval.current = setInterval(() => {
                changeValue();
            }, 150);
        }
    }
    
    return ( 
        <svg x={x} y={y} width="30" height="30" onMouseDown={handleClick} onTouchStart={handleClick}  onMouseUp={handleDeClick} onTouchEnd={handleDeClick} onTouchCancel={handleDeClick} style={{ cursor : "pointer"}}>
            <circle cx="15" cy="15" r="12" stroke="#ffffff" strokeWidth="3" fill={color} />
            <path strokeWidth="3" stroke='#ffffff' strokeLinecap="round" d="M 15 10 L 15 20 M 10 15 L 20 15" />
        </svg>
     );
}
 
export default Plus;