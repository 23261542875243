import { configureStore } from '@reduxjs/toolkit'
import usReducer from '../features/usSlice'
import fsReducer from '../features/fsSlice'
import upReducer from '../features/upSlice'
import fpReducer from '../features/fpSlice'
import ucReducer from '../features/ucSlice'

export const store = configureStore({
  reducer: {
        us : usReducer,
        fs : fsReducer,
        up : upReducer,
        fp : fpReducer,
        uc : ucReducer
  },
})

