import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('tensionMaxS')) || 1,
}

export const usSlice = createSlice({
  name: 'us',
  initialState,
  reducers: {
    plusUs: (state) => {
        if(state.value < 5){
            state.value = parseFloat(state.value+0.1);
            localStorage.setItem("tensionMaxS", parseFloat(state.value).toFixed(1));
        }
    },
    moinsUs: (state) => {
        if(state.value > 1){
            state.value = parseFloat(state.value-0.1);
            localStorage.setItem("tensionMaxS", parseFloat(state.value).toFixed(1));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusUs, moinsUs } = usSlice.actions

export default usSlice.reducer