/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, { Fragment, useEffect, useState } from 'react';
import './App.css';
import Set from './components/Set';
import Taux from './components/Taux';
import TensionC from './components/TensionC';
import TensionF from './components/TensionF';
import TensionP from './components/TensionP';
import TensionS from './components/TensionS';
import { useSelector, useDispatch } from 'react-redux';

function App() {

  const usmax = useSelector((state) => state.us.value)
  const fs = useSelector((state) => state.fs.value)

  const upmax = useSelector((state) => state.up.value)
  const fp = useSelector((state) => state.fp.value)

  const ucmax = useSelector((state) => state.uc.value)

  const [width, setwidth] = useState(1200)

  const [height, setheight] = useState(700)

  const [realHeight, setrealHeight] = useState(window.innerHeight)

  const [realWidth, setrealWidth] = useState(window.innerWidth)

  const [tempsP, settempsP] = useState( parseInt(localStorage.getItem('tempsP')) || 1500 )

  const [tempsS, settempsS] = useState( parseInt(localStorage.getItem('tempsS')) || 10 ) 

  const [fmax, setfmax] = useState( parseInt(localStorage.getItem('fmax')) || 15 ) 

  const [fmax2, setfmax2] = useState( parseInt(localStorage.getItem('fmax2')) || 700 ) 

  const [fct, setfct] = useState(-1) 
  
  useEffect(() => {

    window.addEventListener('resize', handleWindowSizeChange);
  
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])
  
  const handleWindowSizeChange = () => {

    setrealHeight(window.innerHeight);
    setrealWidth(window.innerWidth);

  }

  const handleEnter = (e,value) => {
      e.preventDefault();
      setfct(value);
  }

  const handleOut = (e) => {
    e.preventDefault();
      setfct(-1);
  }

  return (
    <div className="svg-container">
      <svg id="container" viewBox="0 0 1200 700" preserveAspectRatio="xMinYMin meet" className="svg-content">             
          <Fragment>
              <TensionP fmax={fmax} color={"#0080ff"} x={`${20}`} y={`${20}`} width={`${width/3+30}`} height={`${height/3-30}`} frequence={fp} tension={upmax} temps={tempsP} />    
              <TensionS fmax={fmax2} color={"#FF0000"} x={`${20}`} y={`${height/3+10}`}  width={`${width/3+30}`} height={`${height/3-20}`} frequence={fs} tension={usmax} temps={tempsS} />
              <TensionC fmax={fmax2} color={"#FF80ff"} x={`${20}`} y={`${2*height/3+10}`}  width={`${width/3+30}`} height={`${height/3-30}`} tension={ucmax} temps={tempsS} />
              <TensionF fmax={fmax} color={"#800080"} x={`${2*width/3-160}`} y={`${2*height/3-(height/3+height/6)/2-height/4}`} width={`${width/3+140}`} height={`${height/2}`} tensionU0={ucmax} tensionSignal={usmax} tensionPorteuse={upmax} tempsSignal={tempsS} frequenceSignal={fs} frequencePorteuse={fp}/>
              <path style={{ cursor : "pointer", outline : "none" }} onPointerEnter={(e) => handleEnter(e,1)} onPointerOut={handleOut} stroke={"#FF0000"} strokeWidth={"10"} fill={"none"} d={`M${width/3+50} ${height/3+10+(height/3-20)/2}  L${width/3+60} ${height/3+10+(height/3-20)/2} Q ${width/3+110} ${height/3+10+(height/3-20)/2} ${width/3+110} ${height/3+10+(height/3-20)/2+50} L${width/3+110} ${2*height/3}`} />
              <path style={{ cursor : "pointer", outline : "none" }} onPointerEnter={(e) => handleEnter(e,3)} onPointerOut={handleOut} stroke={"#FF80ff"} strokeWidth={"10"} fill={"none"} d={`M${width/3+110} ${2*height/3} L${width/3+110} ${2*height/3+(height/3)/2-50} Q ${width/3+110} ${2*height/3+(height/3)/2} ${width/3+60} ${2*height/3+(height/3)/2} L ${width/3+50} ${2*height/3+(height/3)/2} `} />
              <path style={{ cursor : "pointer", outline : "none" }} onPointerEnter={(e) => handleEnter(e,4)} onPointerOut={handleOut} stroke={"#0080ff"} strokeWidth={"10"} fill={"none"} d={`M${width/3+50} ${(height/3-20)/2+20} L${width/3+130} ${(height/3-20)/2+20} Q ${width/3+180} ${(height/3-20)/2+20} ${width/3+140+40} ${(height/3-20)/2+50+20} L${width/3+140+40} ${(height/3-20)/2+50+height/4}`} />
              <path style={{ cursor : "pointer", outline : "none" }} onPointerEnter={(e) => handleEnter(e,2)} onPointerOut={handleOut} stroke={'#00ff00'} strokeWidth={"10"} fill={"none"} d={`M ${width/3+180} ${2*height/3*0.6} L${width/3+180} ${2*height/3-50} Q ${width/3+180} ${2*height/3} ${width/3+130} ${2*height/3} L ${width/3+90} ${2*height/3}`} />
              <path style={{ cursor : "pointer", outline : "none" }} onPointerEnter={(e) => handleEnter(e,5)} onPointerOut={handleOut} stroke={"#800080"} strokeWidth={"10"} fill={"none"} d={`M${width/3+200} ${(height/3)*1.25} L${2*width/3-160} ${(height/3)*1.25}`} />
              <rect x={width/3+150} y={(height/3)*1.25-30} width="60" height="60" rx="10" ry="10" fill='#444444' />
              <path stroke={"#FFFFFF"} strokeLinecap="round" strokeWidth={"8"} fill={"none"} d={`M${width/3+150+15+2} ${(height/3)*1.25-25+2+10} L${width/3+130+80-15-2} ${(height/3)*1.25+15-2} M${width/3+150+15+2} ${(height/3)*1.25+15-2} L${width/3+130+80-15-2} ${(height/3)*1.25-15+2}`} />
              <rect x={width/3+80} y={2*height/3-30} width="60" height="60" rx="10" ry="10" fill='#444444' />
              <path stroke={"#FFFFFF"} strokeLinecap="round" strokeWidth={"8"} fill={"none"} d={`M${width/3+97} ${2*height/3} L${width/3+123} ${2*height/3} M${width/3+110} ${2*height/3-13} L${width/3+110} ${2*height/3+13}`} />
              <Set fct={fct} x={width/2-20} y={2*height/3+30} width={width/2} height={height/3-50} />
              <Taux x={`${2*width/3-160}`} y={`${2*height/3-(height/3+height/6)/2-height/4-60}`} width={`${width/3+140}`} height={40} tensionU0={ucmax} tensionS={usmax}/>
          </Fragment>      
      </svg>
    </div>
  );

}

export default App;
