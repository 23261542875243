/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect} from 'react';

const TensionP = ({x,y,width,height,tensionS,tensionU0}) => {

   const [taux, settaux] = useState(0)

    useEffect(() => {

        let t = tensionS/tensionU0;
        settaux(t);

    }, [tensionS,tensionU0])
    
    if( String(parseFloat(taux).toFixed(2)) !== "1.00" ){
        return ( 
            <svg  className='tension' x={x} y={y} width={width} height={height} >
                <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#f6f6f6' />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill={parseFloat(taux).toFixed(2) < 1 ? "#209a12" : "#ff0000"} style={{fontFamily:'LatoBold', fontSize: "19px", fontWeight: "bold"}}>Taux de modulation : {String(parseFloat(taux).toFixed(2)).replace(".",",")} { parseFloat(taux).toFixed(2) < 1 ? " | Modulation de bonne qualité" : " | Surmodulation" }</text>
            </svg>
        );
    }else{
        return ( 
            <svg  className='tension' x={x} y={y} width={width} height={height} >
                <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#f6f6f6' />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#ff0000" style={{fontFamily:'LatoBold', fontSize: "19px", fontWeight: "bold"}}>Taux de modulation : {String(parseFloat(taux).toFixed(2)).replace(".",",")} | Modulation critique</text>
            </svg>
        );
    }
}
 
export default TensionP;