import React, {useState,useEffect, Fragment} from 'react';
import Fct from './Fct';
import Graphique from './Graphique';

const TensionF = ({x,y,width,height,tensionU0,tensionSignal,tensionPorteuse,frequencePorteuse,frequenceSignal,tempsSignal,color,fmax}) => {

    const [xList, setxList] = useState([])

    const [xList2, setxList2] = useState([])

    const [yList, setyList] = useState([])

    const [yList2, setyList2] = useState([])

    const [deltaX, setdeltaX] = useState(0.01)

    const [mode, setmode] = useState(true)

    const [config, setconfig] = useState({
        xMin : 0,
        xMax : tempsSignal,
        yMin : parseFloat(-1*tensionPorteuse*(tensionU0+tensionSignal)*1.5),
        yMax : parseFloat(tensionPorteuse*(tensionU0+tensionSignal)*1.5),
        grandeurY : "U",
        uniteY : "V",
        grandeurX : "t",
        uniteX : "ms",
    })

    useEffect(() => {

        setconfig({
            xMin : 0,
            xMax : tempsSignal,
            yMin : parseFloat(-1*tensionPorteuse*(tensionU0+tensionSignal)*1.5),
            yMax : parseFloat(tensionPorteuse*(tensionU0+tensionSignal)*1.5),
            grandeurY : "U",
            uniteY : "V",
            grandeurX : "t",
            uniteX : "ms",
        })

        let x = 0;
        let liX = [];
        let liY = [];
      
        while(x <= config.xMax){
            liX.push(parseFloat(x).toFixed(2));
            liY.push(parseFloat(tensionPorteuse*Math.cos(2*3.14*frequencePorteuse*x)*(tensionU0+tensionSignal*Math.cos(2*3.14*frequenceSignal*0.001*x))));
            x += deltaX;
        }

        setxList(liX);
        setyList(liY);

        let Ufp = tensionU0*tensionPorteuse;

        let Ufpfs = (tensionPorteuse*tensionSignal)/2;

        let x2 = 0;
        let liX2 = [];
        let liY2 = [];

        while(x2 <= fmax){
            if(parseFloat(x2).toFixed(2) === parseFloat(frequencePorteuse).toFixed(2)){
                liY2.push(parseFloat(Ufp));
            }else if(parseFloat(x2).toFixed(2) === parseFloat(frequencePorteuse+frequenceSignal*0.001).toFixed(2)){
                liY2.push(parseFloat(Ufpfs));
            }else if(parseFloat(x2).toFixed(2) === parseFloat(frequencePorteuse-frequenceSignal*0.001).toFixed(2)){
                liY2.push(parseFloat(Ufpfs));
            }else{
                liY2.push(parseFloat(0));
            }
            liX2.push(parseFloat(x2).toFixed(2));
            x2 += deltaX;
        }

        setxList2(liX2);
        setyList2(liY2);

    }, [tensionSignal,tempsSignal,frequenceSignal,tensionU0,frequencePorteuse])

    const handleClick = () => {
        setmode(!mode);
    }

    return ( 
        <svg  className='tension' x={x} y={y} width={width} height={height} >
            <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#FFFFFF' />
            <Graphique config={config} i={1} show={mode === true} mode={1} line="2" lx={xList} ly={yList} colcourbe={color} dxmax={1} dxmin={0.5} dymax={10} dymin={2} xmin={config.xMin} xmax={config.xMax} ymin={config.yMin} ymax={config.yMax} x0={35} y0={height/2} width={width-80} height={height-70} xLabel={`${config.grandeurX}(${config.uniteX})`} yLabel={`${config.grandeurY}(${config.uniteY})`}/>       
            <Graphique config={config} i={3} show={mode === false} mode={2} line="4" lx={xList2} ly={yList2} colcourbe={color} dxmax={0.5} dxmin={0.25} dymax={4} dymin={2} xmin={parseInt(frequencePorteuse-1.7)} xmax={parseInt(frequencePorteuse+1.7)} ymin={0} ymax={tensionU0*tensionPorteuse+2} x0={35} y0={height-30} width={width-80} height={height-70} xLabel={`f(kHz)`} uniteF={'kHz'} yLabel={`${config.grandeurY}(${config.uniteY})`}/>       
            <Fct info={mode === true ? "U(f)" : "U(t)"} width={width} height={height} color={color} onClick={handleClick}/>
            <text x={width*0.5-80} y="25" fill={color} style={{fontFamily:'LatoBold', fontSize: "22px", fontWeight: "bold"}}>Signal modulé</text>
        </svg>
     );
}
 
export default TensionF;